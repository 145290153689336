.get-counsilling .main-img-class {
    position: relative;
}

.get-counsilling .main-img-class .bg-counselling {
    width: 100%;
}

.get-counsilling .main-img-class .get-councelling {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #707070;
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
}


.get-counsilling .yellow-bgggg {
    background: #FFF8EE;
    border-radius: 10px;
    padding: 20px;
}

.myswiper .university-card {
    background: #FFFFFF;
    box-shadow: inset 0px 2px 3px #00000029, 0px 2px 3px #00000029;
    padding: 15px 10px;
    border-radius: 15px;
}

.myswiper .uni-imgg {
    width: 60px;
    background: #efecec;
    border: 0.5px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-left: -20px;
}

.myswiper .uni-txtt {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.myswiper .txtt {
    font-size: 12px;
    color: #707070;
    margin-bottom: 0;

}

.myswiper .blk-border {
    border-right: 2px solid #000;
    margin: 0px 4px;
}

.myswiper .course-txtt {
    font-size: 12px;
    color: #0050C8;
}

.myswiper .fee-txttt {
    font-size: 12px;
}

.myswiper .down-brochure {
    background: #F3F3F3;
    font-size: 12px;
    border: none;
    padding: 5px;
}

.myswiper .book-icon {
    border: 1px solid #989898;
    padding: 5px;
    border-radius: 50px;
    color: #989898;
}