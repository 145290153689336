.account-setting {
    margin-bottom: 10%;
    margin-top: -7%;

}

.account-setting .account-title {
    font-size: 22px;
    color: #131313;
    font-weight: 600;

}

.account-setting .white-bggg {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 10px;
    padding: 20px 180px;
}

.account-setting .main-class-setting {
    cursor: pointer;
}

.account-setting .main-class-setting .setting-txttt {
    font-size: 14px;
    color: #707070;
    font-weight: 500;
}

.account-setting .main-class-setting:hover .setting-txttt {
    color: #FF9100;
}

.account-setting .main-class-setting .right-angal-icon {
    font-size: 16px;
    color: #707070;
    font-weight: 700;
    transition: color 0.3s ease, margin-right 0.3s ease;
}

.account-setting .main-class-setting:hover .right-angal-icon {
    color: #FF9100;
    margin-right: -13px;
}

/* media */

@media(min-width:0px) and (max-width:576px) {
    .account-setting .white-bggg {
        padding: 20px;
    }

    .account-setting .account-title {
        font-size: 18px;
    }

    .account-setting .main-class-setting .setting-txttt {
        font-size: 13px;
    }

    .account-setting .main-class-setting .right-angal-icon {
        font-size: 14px;
    }

    .account-setting {
        margin: 10% 0%;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .account-setting .white-bggg {
        padding: 20px;
    }

    .account-setting .account-title {
        font-size: 18px;
    }

    .account-setting .main-class-setting .setting-txttt {
        font-size: 13px;
    }

    .account-setting .main-class-setting .right-angal-icon {
        font-size: 14px;
    }

    .account-setting {
        margin: 10% 0%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .account-setting .white-bggg {
        padding: 20px 60px;
    }

    .account-setting .account-title {
        font-size: 20px;
    }

    .account-setting .main-class-setting .setting-txttt {
        font-size: 13px;
    }

    .account-setting .main-class-setting .right-angal-icon {
        font-size: 15px;
    }

}

@media(min-width:993px) and (max-width:1024px) {
    .account-setting {
        margin-bottom: 10%;
        margin-top: -10%;
    }

}

@media(min-width:1025px) and (max-width:1440px) {
    .account-setting {
        margin-bottom: 10%;
        margin-top: -9%;
    }

}