.LatestNewsDescription .news-description-img .late-main .news-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.4;
}

.LatestNewsDescription .news-description-img {
  position: relative;
}

.LatestNewsDescription .news-description-img .late-main .offer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 15px;
  padding: 10px 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50px 0px 8px 0px;
  color: #ff9100;
  z-index: 1;
}

.LatestNewsDescription .news-description-img .late-main .card-heading {
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 1;
}

.LatestNewsDescription .news-description-img .late-main .card-heading h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.LatestNewsDescription .news-description-img .late-main .overlay {
  position: absolute;
  bottom: 0px;
  background-image: linear-gradient(180deg, #fff0 0%, #fff 100%);
  height: 50%;
  width: 100%;
}

.LatestNewsDescription .news-description-text-holder {
  margin-top: 5%;
}

.LatestNewsDescription .news-description-text-holder p {
  /* text-align: left; */
  font-size: 15px;
  font-weight: 500;
  color: #202020;
  text-align: justify;
}
@media (min-width: 0px) and (max-width: 320px) {
  .LatestNewsDescription {
    margin-top: 5%;
  }

  .LatestNewsDescription .news-description-img .late-main .offer {
    font-size: 8px;
    padding: 5px 16px;
  }
  .LatestNewsDescription .news-description-img .late-main .card-heading h3 {
    font-size: 11px;
  }

  .LatestNewsDescription .news-description-text-holder p {
    font-size: 11px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .LatestNewsDescription {
    margin-top: 5%;
  }

  .LatestNewsDescription .news-description-img .late-main .offer {
    font-size: 10px;
    padding: 5px 20px;
  }

  .LatestNewsDescription .news-description-img .late-main .card-heading h3 {
    font-size: 15px;
  }

  .LatestNewsDescription .news-description-text-holder p {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .LatestNewsDescription {
    margin-top: 5%;
  }

  .LatestNewsDescription .news-description-img .late-main .offer {
    font-size: 13px;
    padding: 5px 30px;
  }
  .LatestNewsDescription .news-description-img .late-main .card-heading h3 {
    font-size: 15px;
  }

  .LatestNewsDescription .news-description-text-holder p {
    font-size: 12px;
  }
}


@media (min-width: 576px) and (max-width: 767px) {
    .LatestNewsDescription {
      margin-top: 5%;
    }
  
    .LatestNewsDescription .news-description-img .late-main .offer {
      font-size: 13px;
      padding: 5px 30px;
    }
    
    .LatestNewsDescription .news-description-img .late-main .card-heading h3 {
        font-size: 18px;
    }
  
    .LatestNewsDescription .news-description-text-holder p {
      font-size: 12px;
    }
  }
  

  @media (min-width: 768px) and (max-width: 991px) {
  
    .LatestNewsDescription .news-description-img .late-main .offer {
      font-size: 13px;
      padding: 5px 30px;
    }
    .LatestNewsDescription .news-description-img .late-main .card-heading h3 {
        font-size: 18px;
    }
  
    .LatestNewsDescription .news-description-text-holder p {
      font-size: 12px;
    }
  }