.confused-get {
    background: #FFF6EB;
    border-radius: 10px;
    padding: 40px;
    margin: 5% 0;
}

.confused-get .confused-btnnn {
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
}


/* Media */

@media(min-width:0px) and (max-width:768px) {
    .confused-get .confused-btnnn {
        font-size: 12px;
        margin-top: 12px;
    }

    .confused-get .heading {
        text-align: center;
    }
}