.univer-slider .swiper {
    width: 100%;
}

.univer-slider .nav-pills .nav-link {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px;
}


.univer-slider .nav-pills .nav-link.active {
    background: #F4E0C6 0% 0% no-repeat padding-box;
    padding: 10px;
}


.univer-slider .uni-img {
    width: 100%;
    aspect-ratio: 5/2;
}

.univer-slider .silder-btn {
    position: relative;
}

.univer-slider .silder-btn .back-btn {
    position: absolute;
    z-index: 999;
    right: 0px;
    bottom: -20px;
}

.univer-slider .silder-btn .next-btn {
    position: absolute;
    z-index: 999;
    right: 35px;
    bottom: -20px;
}

.univer-slider .silder-btn .icon-btnnn {
    color: #FFF;
    font-weight: bold;
    font-size: 15px;
    background: #C4C4C4;
    border-radius: 50%;
    padding: 4px 7px;
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 991px) {
    .univer-slider .trending-cources {
        margin: 5% 0;
    }
}