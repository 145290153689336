.Online_degree_hover_card {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    position: relative;
    padding: 15px 15px;
}

.Online_degree_hover_card:before {
    content: "";
    display: block;
    position: absolute;
    border: 2px solid #ee9723;
    border-radius: 3px;
    left: 0px;
    height: 100%;
    top: 0px;
}

.Online_degree_hover_card .img-holder .online-degree-img {
  width: 100%;
  height: 100%;
}

.Online_degree_hover_card .text-holder .card-heading-holder h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #202020;
}

.Online_degree_hover_card .text-holder .content-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #202020;
}
