.Online_Degree {
  margin: 5% 0;
}

.Online_Degree .degree {
  margin-top: 30px;
}

.Online_Degree .heading {
  text-align: center;
}

.Online_Degree .degree {
  position: relative;
}

.Online_Degree .degree .main-card-holder .hover-card-holder {
    display: none;
} 

.Online_Degree .degree .main-card-holder:hover .hover-card-holder {
  display: block;
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
}


