.university-banner {
    position: relative;
}

.university-banner .uni-imgg {
    width: 100%;
    background: #F5F5F5;
    border-radius: 7px;
    padding: 15px;
    width: 116px;
}

.university-banner .banner-divvv {
    position: absolute;
    top: 50%;
    left: 26%;
}

.university-banner .uni-txtt {
    font-size: 20px;
    color: #FFFFFF;
    
}

.university-banner .txtt {
    font-size: 13px;
    color: #FFF;
    margin-bottom: 0;
  
}

.university-banner .white-border {
    border-right: 2px solid #FFF;
    margin: 0px 7px;
}