.common-modalll-class .nav-pills .nav-link {
    font-size: 14px;
    color: #000000;
    border: 1px solid #707070;
    border-radius: 5px;
    background: transparent;
    margin: 10px 10px 0px 0px;
    padding: 8px 25px;
}

.common-modalll-class .nav-pills .nav-link.active {
    font-size: 14px;
    color: #000000;
    border: 1px solid #FF9100;
    border-radius: 5px;
    background: #FFF8EE;
}

.common-modalll-class .brown-border {
    border-bottom: 1px solid #707070;
}

.common-modalll-class .title-details {
    font-size: 14px;
    color: #202020;
}

.common-modalll-class .blue-txttt {
    color: #0050C8;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}


.common-modalll-class .css-13cymwt-control {
    background: #F3F3F3;
    font-size: 14px;
    border: none !important;
}


.common-modalll-class .grey-bgggg {
    background: #F8F8F8;
    border-radius: 15px;
    padding: 15px;
    margin-top: 10px;
}

.common-modalll-class .user-deatils-first {
    font-size: 14px;
    font-weight: 600;
    color: #131313;

}

.common-modalll-class .user-deatils {
    font-size: 12px;
    color: #707070;
}

.common-modalll-class .user-deatils-secon {
    font-size: 13px;
    font-weight: 600;
}

.common-modalll-class .social-icon {
    cursor: pointer;
    font-size: 13px;
    color: #707070;
}