.online-degree {
    padding: 40px 0px;
    background: #FFF6EB;
    margin: 5% 0;
}

.online-degree .main-class-img {
    text-align: center;
    background: #FFFFFF;
    padding: 15px 40px;
    border-radius: 5px;
    margin: auto;
}

.online-degree .img-classs {
    width: 80px;
    height: 100%;
    aspect-ratio: 3/3;
}