.edit-profile {
    margin-top: -7%;
    margin-bottom: 5%;
}

.edit-profile .white-bggg {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 10px;
    padding: 50px 180px;
}

.edit-profile .css-hlgwow {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    color: #707070;
    font-weight: 500;
    height: 40px;
    border: 1px solid #FFF;
}

.edit-profile .css-1xc3v61-indicatorContainer {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    color: #000;
}

.edit-profile .css-1xc3v61-indicatorContainer:hover {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    color: #000;
}

.edit-profile .orange-main {
    position: relative;
}

.edit-profile .username {
    background: #FF9100;
    border-radius: 50px;
    border: none;
    color: #fff;
    font-size: 22px;
    padding: 12px 14px;
    width: fit-content;
    font-weight: 600;
    cursor: pointer;
}


.edit-profile .orange-main .pen-icon {
    position: absolute;
    bottom: 14px;
    left: 46px;
    background: #FFF;
    padding: 8px;
    border-radius: 50px;
    font-size: 12px;
    box-shadow: 0px 0px 3px #0000006B;
    cursor: pointer;
}

.edit-profile .blue-txttt {
    color: #0050C8;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.edit-profile .cross-icon {
    color: #FFF;
    background: #707070;
    font-size: 14px;
    padding: 6px;
    border-radius: 50px;
    cursor: pointer;
}

.edit-profile .title-activity {
    font-size: 22px;
    color: #131313;
    font-weight: 700;
}

/* Media */

@media (min-width:0px) and (max-width:576px) {
    .edit-profile .white-bggg {
        padding: 15px;
    }
}