.specialization .yellow-bggg {
    background: #FFF8EE;
    border: 0.5px solid #707070;
    border-radius: 5px;
    padding: 10px;
    height: 80px;
    margin: 12px 0px;
}

.specialization .yellow-bggg .course-nameee {
    font-size: 14px;
}