.institute-information .first-card {
    background: #FFF8EE 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 10px;
    padding: 10px;
}

.institute-information .blk-border {
    border-bottom: 1px solid #000;
    margin-top: 5%;
}

.institute-information .normal-txtt {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.institute-information .bold-txtt {
    font-weight: 600;
}


.institute-information .lined-heading {
    position: relative;
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
}

.institute-information .lined-heading::before,
.institute-information .lined-heading::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 15%;
    height: 1px;
    background-color: #000;
}

.institute-information .lined-heading::before {
    left: 0;
}

.institute-information .lined-heading::after {
    right: 0;
}

.institute-information .light-blue-bggg {
    background: #F9FBFF;
    height: 214px;
    border-radius: 15px;
}