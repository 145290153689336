.course-details-banner .title-banner .banner-titleee {
    font-size: 78px;
}

.course-details-banner .course-details-banner-txtt h4 {
    font-size: 24px;
    font-weight: 600;
    color: #FFF;
}

.course-details-banner .course-details-banner-txtt p {
    font-size: 16px;
    color: #FFF;
    margin-top: 4px;
}

.course-details-banner .course-details-banner-txtt .orange-border {
    border: 0.5px solid #FF9100;
}

.course-details-banner .course-details-banner-txtt {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.course-details-banner .search-fieldd {
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media(min-width:0px) and (max-width:576px) {
    .course-details-banner .title-banner .banner-titleee {
        font-size: 20px;
    }

    .course-details-banner .search-fieldd {
        position: unset;
        transform: none;
        margin: 0% 9%;
    }

    .course-details-banner .course-details-banner-txtt {
        position: unset;
        transform: none;
        margin: 9%;
    }

    .course-details-banner .course-details-banner-txtt h4 {
        font-size: 20px;
        color: #000;
        text-align: center;
        margin-top: 5%;
    }

    .course-details-banner .course-details-banner-txtt p {
        font-size: 14px;
        color: #000;
        margin-top: 4px;
        text-align: center;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .course-details-banner .title-banner .banner-titleee {
        font-size: 20px;
    }

    .course-details-banner .search-fieldd {
        position: unset;
        transform: none;
        margin: 0% 9%;
    }

    .course-details-banner .course-details-banner-txtt {
        position: unset;
        transform: none;
        margin: 9%;
    }

    .course-details-banner .course-details-banner-txtt h4 {
        font-size: 20px;
        color: #000;
        text-align: center;
        margin-top: 5%;
    }

    .course-details-banner .course-details-banner-txtt p {
        font-size: 14px;
        color: #000;
        margin-top: 4px;
        text-align: center;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .course-details-banner .title-banner .banner-titleee {
        font-size: 30px;
    }

    .course-details-banner .search-fieldd {
        position: unset;
        transform: none;
        margin: 5% 9%;
    }

    .course-details-banner .course-details-banner-txtt {
        top: 26%;
    }

    .course-details-banner .course-details-banner-txtt h4 {
        font-size: 18px;
        color: #FFF;
        margin-top: 4px;
        text-align: center;
    }

    .course-details-banner .course-details-banner-txtt p {
        font-size: 14px;
        color: #FFF;
        margin-top: 4px;
        text-align: center;
    }
}


@media(min-width:993px) and (max-width:1024px) {
    .course-details-banner .title-banner .banner-titleee {
        font-size: 30px;
    }

    .course-details-banner .search-fieldd {
        top: 38%;
    }

    .course-details-banner .course-details-banner-txtt {
        top: 28%;
    }

    .course-details-banner .course-details-banner-txtt h4 {
        font-size: 18px;
        color: #FFF;
        margin-top: 4px;
        text-align: center;
    }

    .course-details-banner .course-details-banner-txtt p {
        font-size: 14px;
        color: #FFF;
        margin-top: 4px;
        text-align: center;
    }
}