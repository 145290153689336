.commonbanner  {
    position: relative;
    top: -102px;
    z-index: -1;
}

.commonbanner .main-class-banner .banner-img {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.commonbanner .main-class-banner .blk-overlay {
    background: #0000 linear-gradient(180deg, #000000b3, #000000b3) 0 0 no-repeat padding-box;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0px;
    width: 100%;
}
.commonbanner .banner-overlay-bottom-up {
    position: absolute;
    left: 0;
    bottom: 0;
}

.commonbanner .banner-overlay-top-up {
    position: absolute;
    right: 0;
    top: 0;
}

.commonbanner .overlay-img {
    width: 100%;
    height: 400px;
}

.commonbanner .title-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.commonbanner .title-banner .banner-titleee {
    color: #FFF;
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}


/* Media Query */

@media(min-width:0px) and (max-width:576px) {

 
    .commonbanner .overlay-img {
        height: 100px;
    }

    .commonbanner  {
        position: relative;
        top: 0px;
    }

    .commonbanner .title-banner .banner-titleee {
      font-size: 20px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .commonbanner .overlay-img {
        height: 100px;
    }

    .commonbanner {
        position: relative;
        top: 0px;
    }

    .commonbanner .title-banner .banner-titleee {
        font-size: 30px;
    }
}

@media(min-width:768px) and (max-width:1024px) {
    .commonbanner .overlay-img {
        height: 150px;
    }

    .commonbanner {
        position: relative;
        top: -76px;
    }

    .commonbanner .title-banner .banner-titleee {
        font-size: 35px;
    }
}