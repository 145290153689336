.RequestCallBack {
  margin-top: 10%;
  position: relative;
}

.RequestCallBack:before {
  border-top: 3px solid #f8ac3c;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  width: 20px;
  height: fit-content;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}

.RequestCallBack:hover.RequestCallBack:before {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.RequestCallBack .RequestCallBack-form-holder {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px 40px 20px 40px;
}

.RequestCallBack .RequestCallBack-form-holder .form-heading-holder h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.RequestCallBack .RequestCallBack-form-holder .form-holder {
  margin-top: 8%;
}

.RequestCallBack .RequestCallBack-form-holder .form-holder .form-control {
  background: #fff;
  border: 1px solid #7070704d;
  border-radius: 5px;
  color: #000;
  font-size: 15px;
}

.RequestCallBack .RequestCallBack-form-holder .form-holder .common-btn-holder {
  text-align: center;
  margin-top: 5%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .RequestCallBack .RequestCallBack-form-holder {
    padding: 20px 20px 20px 20px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .RequestCallBack .RequestCallBack-form-holder {
    padding: 20px 20px 20px 20px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .RequestCallBack .RequestCallBack-form-holder {
    padding: 20px 20px 20px 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .RequestCallBack .RequestCallBack-form-holder {
    padding: 20px 20px 20px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .RequestCallBack .RequestCallBack-form-holder {
    padding: 20px 20px 20px 20px;
  }

  .RequestCallBack .RequestCallBack-form-holder .form-heading-holder h3 {
    font-size: 16px;
}

.RequestCallBack .RequestCallBack-form-holder .form-holder .form-control {
    font-size: 12px;
}
}
