/* IndustryExpert css started */

.IndustryExpert {
    margin: 10% 0 5% 0;
}

.IndustryExpert .expert-back {
    background: #e4e4e4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    padding: 26px 15px;
}
.IndustryExpert .main-expert .image-person img.quatient {
    height: 100%;
    width: 100%;
    border-radius: 100px;
}
.IndustryExpert .main-expert .image-person {
    border-radius: 50px;
    width: 100%;
    height: 74px;
    margin: 23px 0px;
}
.IndustryExpert .expert-back .main-expert .content .text {
    color: #6d6d6d;
    font-weight: 400;
    margin: 0px 16px;
}
.IndustryExpert .expert-back .main-expert .content .sub-text-main {
    margin-bottom: 0px;
}

.IndustryExpert .expert-back .quatient img.quatient {
    width: 100%;
    height: 100%;
}
.IndustryExpert .expert-back .quatient {
    width: 89%;
}
.IndustryExpert .expert-back .main-expert .content .text-main {
    margin-bottom: 0px;
}
.IndustryExpert .expert-back .main {
    text-align: -webkit-center;
}
.IndustryExpert .swiper {
    padding-bottom: 3%;
    padding-top: 1%;
}
.IndustryExpert .swiper .swiper-pagination-bullet-active {
    border-radius: 50px;
    height: 4px;
    width: 26px;
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #ff9100;
}
.IndustryExpert .swiper span.swiper-pagination-bullet {
    border-radius: 50px;
    height: 4px;
    width: 26px;
    background: #ff9100 0% 0% no-repeat padding-box;
    border-radius: 50px;
}
.IndustryExpert .expert-back .image-person {
    margin-top: 43%;
}

.IndustryExpert .expert-back .image-person img.quatient-main {
    width: 100%;
    height: 100%;
}
.IndustryExpert .expert-back  .text {
    color: #6d6d6d;
    font-weight: 400;
    margin: 0px 15px;
}
/* IndustryExpert css End */

/* Media Query started */
@media (min-width: 0px) and (max-width: 485px) {
    .IndustryExpert .expert-back .quatient {
        width: 67px;
    }
    .IndustryExpert .expert-back .main {
        text-align: end;
    }
    .IndustryExpert .main-expert .image-person {
        width: 76px;
        height: 76px;
    }
    .IndustryExpert .text-main {
        font-size: 13px;
        font-family: "Roboto";
        text-align: justify;
    }
    .IndustryExpert .swiper {
        padding-bottom: 12%;
        padding-top: 1%;
    }
    .IndustryExpert .expert-back .image-person {
        margin-top: 0%;
        height: 76px;
        width: 76px;
    }
    .IndustryExpert .expert-back .sub-text-main {
        margin-bottom: 0px;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .IndustryExpert .expert-back .quatient {
        width: 82%;
    }
    .IndustryExpert .expert-back .main {
        text-align: end;
    }
    .IndustryExpert .main-expert .image-person {
        width: 76px;
        height: 76px;
    }
    .IndustryExpert .text-main {
        font-size: 14px;
        font-family: "Roboto";
        text-align: justify;
    }
    .IndustryExpert .swiper {
        padding-bottom: 7%;
        padding-top: 1%;
    }
    .IndustryExpert .expert-back .image-person {
        margin-top: 0%;
        height: 76px;
        width: 76px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .IndustryExpert .expert-back .quatient {
        width: 82%;
    }
    .IndustryExpert .expert-back .image-person {
        margin-top: 0%;
        height: 76px;
        width: 76px;
    }
    .IndustryExpert .swiper {
        padding-bottom: 8%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .IndustryExpert .expert-back .quatient {
        width: 77%;
    }
    .IndustryExpert .expert-back .image-person {
        margin-top: 0%;
        height: 76px;
        width: 76px;
    }
    .IndustryExpert .swiper {
        padding-bottom: 5%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .IndustryExpert .expert-back .image-person {
        margin-top: 117%;
    }
    .IndustryExpert .expert-back .quatient {
        width: 100%;
    }
}
