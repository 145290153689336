.profile-data {
    margin-top: -7%;
    margin-bottom: 5%;
}

.profile-data .CircularProgressbar .CircularProgressbar-text {
    fill: #000;
    font-size: 14px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: 600;
    position: absolute;
    bottom: 0;
}

.profile-data .CircularProgressbar .CircularProgressbar-path {
    stroke: #FF9100;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.profile-data .main-circular {
    position: relative;
}

.profile-data .white-bggg {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px 0px 20px 80px;
    border-right: 20px solid #FF9100;
}

.profile-data .white-bggg .user-name {
    font-size: 22px;
    font-weight: 700;
    color: #131313;
}

.profile-data .white-bggg .user-deatils {
    font-size: 14px;
    color: #131313;
}

.profile-data .white-bggg .three-dots {
    color: #FFF;
    background: #FF9100;
    padding: 6px 11px;
    border-radius: 5px;
    cursor: pointer;
}

.profile-data .light-cream-bggg {
    background: #FFF8EE;
    border-radius: 5px;
    position: absolute;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 0px;
    right: 30px;


}

.profile-data .light-cream-bggg p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
    cursor: pointer;
    padding: 10px 50px 5px 20px;
    border-radius: 5px;

}

.profile-data .light-cream-bggg p:hover {
    background: #202020;
    color: #FFF;
}

.profile-data .dropdown-container {
    position: relative;
    display: inline-block;
}

.profile-data .white-bggg-section {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px 20px 20px 80px;
    margin-top: 3%;
    border-right: 20px solid #FFF;
}

.profile-data .title-details {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.profile-data .user-deatils-main {
    font-size: 14px;
    color: #131313;
    font-weight: 600;
    margin-right: 8px;
}

.profile-data .blue-txttt {
    font-size: 14px;
    cursor: pointer;
    color: #0050C8;
    font-weight: 600;
}

.profile-data .grey-bgggg {
    background: #F8F8F8;
    border-radius: 15px;
    padding: 15px;
    margin-top: 10px;
}

.profile-data .grey-bgggg .user-deatils-secon {
    font-weight: 600;
    font-size: 12px;
}

@media(min-width:0px) and (max-width:576px) {
    .profile-data .white-bggg {
        border-right: 15px solid #FF9100;
        padding: 20px 0px 20px 20px;
    }

    .profile-data .white-bggg .user-name {
        font-size: 20px;
    }

    .profile-data .white-bggg .user-deatils {
        font-size: 12px;
    }

    .profile-data .title-details {
        font-size: 16px;
    }

    .profile-data .blue-txttt {
        font-size: 13px;
    }
}


@media(min-width:577px) and (max-width:768px) {
    .profile-data .white-bggg {
        border-right: 15px solid #FF9100;
        padding: 20px 0px 20px 20px;
    }

    .profile-data .white-bggg .user-name {
        font-size: 20px;
    }

    .profile-data .white-bggg .user-deatils {
        font-size: 12px;
    }

    .profile-data .title-details {
        font-size: 16px;
    }

    .profile-data .blue-txttt {
        font-size: 13px;
    }
}