.university-banner .rating-txtt {
    color: #FF9100;
}

.university-banner .icon-bgg {
    background: #FFF;
    border-radius: 50%;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.university-banner .social-icon-divvv {
    display: flex;
    position: absolute;
    bottom: 115px;
    right: 26px;
}

.university-banner .icon-imggg-txtt {
    width: 15px;
}