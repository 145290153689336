.popular-comparision {
    margin: 5% 0;
}

.popular-comparision .blue-bggg {
    background: #F9FBFF;
    border-radius: 20px;
    padding: 15px;
}

.popular-comparision .blue-bggg .first-card {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.popular-comparision .blue-bggg .grey-bgggg {
    background: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 40px;
}

.popular-comparision .blue-bggg .uni-txttt {
    font-size: 13px;
    font-weight: 600;
}

.popular-comparision .blue-bggg .uni-img {
    width: 90px;
}

.popular-comparision .blue-bggg .below-section {
    padding: 10px;
}

.popular-comparision .blue-bggg .course-txtt {
    font-size: 13px;
    color: #000;
}

.popular-comparision .blue-bggg .icon {
    color: #575757;
}

.popular-comparision .section-class {
    position: relative;
}

.popular-comparision .vc-class {
   position: absolute;
   bottom: -30px;
   left: 43%;
}

.popular-comparision .vc-class .vs-txtt {
    font-size: 13px;
    color: #FFF;
    background: #202020;
    border-radius: 50px;
    padding: 10px;
    width: fit-content;
    border: 2px solid #FF9100;
}