.main-cources {
  margin: 0 0 5% 0;
}

.main-cources .yellow-bggg {
  background: #fff8ee;
  border-radius: 10px 0px 0px 10px;
  padding: 20px 0px 20px 10px;
  height: 100%;
}

.main-cources .yellow-bggg .nav-pills .nav-link {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  padding: 15px;
}

.main-cources .yellow-bggg .nav-pills .nav-link.active {
  font-weight: 700;
  color: #000;
  background: transparent;
}

.main-cources .custom-nav-link {
  position: relative;
  padding-right: 60px;
}

.main-cources .custom-nav-link.active::before {
  content: "•";
  position: absolute;
  right: 42px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 23px;
  color: #ff9100;
}

.main-cources .custom-nav-link.active::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  border-bottom: 1px dotted;
  border-bottom: 1px solid #ff9100;
}

.main-cources .yellow-bggg .tabs-link-holder {
  display: block;
}



@media (min-width: 0px) and (max-width: 991px) {
  .main-cources .yellow-bggg .tabs-link-holder {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  }

 .main-cources .yellow-bggg .tabs-link-holder::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
 .main-cources .yellow-bggg .tabs-link-holder::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  /* Track */
 .main-cources .yellow-bggg .tabs-link-holder::-webkit-scrollbar-track {
    background: #e8e8e8;
  }
 .main-cources .yellow-bggg .tabs-link-holder::-webkit-scrollbar-track:horizontal {
    background: #e8e8e8;
  }



  .main-cources .custom-nav-link.active::before {
    display: none;
  }

  .main-cources .custom-nav-link.active::after {
    display: none;
  }

  .main-cources .yellow-bggg {
    border-radius: 10px 10px 10px 10px;
    padding: 0px 10px 0px 10px;
    height: auto;
  }

  .main-cources .yellow-bggg .nav-pills .nav-link {
    font-size: 15px;
}
}
