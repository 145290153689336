.Online_degree_card .Online_degree_card_holder {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #e9ecef;
}



.Online_degree_card .Online_degree_card_holder .img-holder .online-degree-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.Online_degree_card .Online_degree_card_holder .image-text-holder {
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.Online_degree_card .Online_degree_card_holder .image-text-holder p {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px;
}

.Online_degree_card .Online_degree_card_holder .overlay-holder {
  position: absolute;
  background-image: linear-gradient(180deg, #fff0 0%, #fff 100%);
  width: 100%;
  height: 50%;
  bottom: 0px;
  border-radius: 10px;
}

.Online_degree_card:after {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  border: 2px solid #ee9723;
  margin-top: 15px;
  border-radius: 3px;
}
