.innercources .white-bgggg {
    display: none;
}

.innercources .course-name {
    text-align: start;
}


.innercources .trending-cource-card .download-brochure {
    font-size: 12px;
}

.innercources .trending-cource-card .compare-college {
    font-size: 12px;
}

