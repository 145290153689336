.programm-eligibility {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 40px 0px;
}

.programm-eligibility .user-img {
    width: 35px;
}

.programm-eligibility .check-img {
    width: 20px;
}