.faicility-section .main-div-class {
    border-left: 2px solid #FF9100; 
    padding: 0px 0px 0px 8px;
}

.faicility-section .main-div-class .txttt {
    font-size: 13px;
    font-weight: 600;
}

.faicility-section .icon-imggg {
    width: 20px;
}