.similar-college {
    margin: 5% 0;
}

.similar-college .first-card {
    background: #FFF8EE;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.similar-college .first-card .white-bgggg {
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 40px;
}

.similar-college .first-card .uni-img {
    width: 100px;
}

.similar-college .first-card .below-section {
    padding: 20px 10px;
}

.similar-college .first-card .below-section .uni-txttt {
    font-size: 15px;
    font-weight: 600;
}

.similar-college .first-card .below-section .location-txtt {
    font-size: 12px;
}

.similar-college .first-card .below-section .course-txtt {
    font-size: 14px;
}


/*  */

.similar-college .light-blue-bggg {
    background: #F9FBFF;
    border-radius: 20px;
    text-align: center;
    padding: 20px 0px;
    margin: auto;
}

.similar-college .light-blue-bggg .plus-icon {
    font-size: 24px;
    font-weight: 600;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 4px 6px;
}

.similar-college .light-blue-bggg .white-bggg {
    border: 1px dashed #707070;
    background: #FFFFFF;
    border: 1px dashed #707070;
    border-radius: 50px;
    padding: 28px 28px;
    width: fit-content;
}
    
.similar-college .light-blue-bggg .white-bggg:hover {
    border: 1px dashed #FF9100;
}

.similar-college .similar-btnn {
    background: #FFFFFF;
    border: 0.5px solid #FF9100;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px 15px;

}

.similar-college .similar-btnn:hover {
    background: #FFF8EE;
}