.login-to-explore {
    margin: 5% 0;
    background: #FFF8EE;
    padding: 60px 0px;
}

.login-to-explore .white-bgg {
    background: #FFFFFF;
    border-radius: 5px;
    text-align: center;
    padding-bottom: 35px;
}

.login-to-explore .white-bgg .brownn-bggg {
    position: relative;
    text-align: center;
    height: 100px;
    width: 200px;
    border-radius: 0 0 150px 150px;
    background-color: #7E7E7E;
    left: 50%;
    top: 1px;
    transform: translate(-50%, 0%);
}

.login-to-explore .white-bgg .brownn-bggg .clock-icon {
    color: #fff;
    font-size: 40px;
    padding: 20px;
}

.login-to-explore .white-bgg .text-main {
    margin-top: 35px;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .login-to-explore .white-bgg {
        margin-top: 7%;
    }
}

@media(min-width:577px) and (max-width:992px) {
    .login-to-explore .white-bgg {
        margin-top: 7%;
    }
}