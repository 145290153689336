.common-modalll-class .modal-body {
    padding: 0;
}

.common-modalll-class .below-txttt {
    margin-top: 15%;
    color: #202020;
    font-size: 26px;
}

.common-modalll-class .first-section {
    padding: 50px 50px 50px 50px;
}

.common-modalll-class .first-section .signup-txttt {
    color: #202020;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 5%;
    margin-top: 35%;
}

.common-modalll-class .first-section .orange-border {
    border-bottom: 2px solid #FF9100;
}

.common-modalll-class .white-bggg {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 10px 30px;
    position: relative;
}

.common-modalll-class .white-bggg .signup-txtttttt {
    font-size: 22px;
    color: #202020;
    font-weight: 600;
}

.common-modalll-class .bg-img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

}

.common-modalll-class .form-main {
    position: relative;
    z-index: 9999;
}

/* form css */

.common-modalll-class .orange-bgg {
    background: transparent linear-gradient(0deg, #f7e9d7 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    padding: 70px 15px 20px 15px;
}

.common-modalll-class .form-control {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    color: #707070;
    font-weight: 500;
    border: 1px solid #F3F3F3;
}

.common-modalll-class .get-btnnn {
    background: #FF9100;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 8px 10px;
}

.common-modalll-class .form-main .form-txttt {
    font-size: 12px;
    color: #707070;
    cursor: pointer;
}

.common-modalll-class .form-txttt {
    font-size: 14px;
    color: #707070;
}

.common-modalll-class .login-txtt {
    color: #FF9100;
    cursor: pointer;
}

.common-modalll-class label {
    font-size: 12px;
    color: #707070;
}

.common-modalll-class .form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.common-modalll-class .react-tel-input .form-control {
    font-size: 14px;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    color: #707070;
    font-weight: 500;
    height: 40px;
    border: 1px solid #F3F3F3;
}


.common-modalll-class .form-holder .phone-input-button {
    border-right: 5px solid #fff;
    flex-shrink: 0 !important;
    margin-right: 10px !important;
    padding: 0 5px !important;
}

.common-modalll-class .second-line {
    font-size: 14px;
    font-weight: 600;
}

.common-modalll-class .resend-txttt{
    text-decoration: underline;
    cursor: pointer;
}

.common-modalll-class .resend-txttt:hover {
    color: #FF9100;
} 

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .common-modalll-class .white-bggg .signup-txtttttt {
        font-size: 20px;
    }

    .common-modalll-class .form-control {
        font-size: 12px;
    }

    .common-modalll-class .get-btnnn {
        font-size: 12px;
    }

    .common-modalll-class .form-txttt {
        font-size: 12px;
    }

    .common-modalll-class .login-txtt {
        font-size: 12px;
    }

    .common-modalll-class label {
        font-size: 10px;
    }

    .common-modalll-class .white-bggg {
        padding: 10px 20px;
    }

    .common-modalll-class .first-section {
        display: none;
    }

    .common-modalll-class .orange-bgg {
        padding: 50px 15px 20px 15px;
    }

}


@media(min-width:577px) and (max-width:991px) {
    .common-modalll-class .white-bggg .signup-txtttttt {
        font-size: 20px;
    }

    .common-modalll-class .form-control {
        font-size: 12px;
    }

    .common-modalll-class .get-btnnn {
        font-size: 12px;
    }

    .common-modalll-class .form-txttt {
        font-size: 12px;
    }

    .common-modalll-class .login-txtt {
        font-size: 12px;
    }

    .common-modalll-class label {
        font-size: 10px;
    }

    .common-modalll-class .white-bggg {
        padding: 10px 20px;
    }

    .common-modalll-class .first-section {
        display: none;
    }

    .common-modalll-class .orange-bgg {
        padding: 50px 15px 20px 15px;
    }

}

@media(min-width:992px) and (max-width:1024px) {
    .common-modalll-class .first-section .signup-txttt {
        font-size: 30px;
        margin-top: 57%;
    }

    .common-modalll-class .below-txttt {
        margin-top: 18%;
        font-size: 21px;
    }
}