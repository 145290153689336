.common-univeresity {
    margin: 5% 0;
}

.common-univeresity .yellow-bgggg {
    background: #FFF1DF;
    padding: 20px 15px 40px 15px;
}

.common-univeresity .sub-headdd {
    font-size: 20px;
    font-weight: 600;
}

.common-univeresity p {
    font-size: 14px;
    text-align: justify;
}

.common-univeresity .uni-img {
    width: 100%;
    height: 100%;
}


.common-univeresity .uni-sec-title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
}

.common-univeresity .white-bggg {
    background: #FFFFFF;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    padding: 15px;
}

.common-univeresity .dash-border {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 10px;
}

.common-univeresity .bold-txttt {
    font-size: 14px;
    font-weight: 600;
}

/* thirs component */

.common-univeresity .blue-bggg {
    background: #F9FBFF;
    box-shadow: 0px 0px 10px #00000026;
    border-radius: 10px;
    padding: 15px;
}

.common-univeresity .bold-txtt-blue {
    font-size: 16px;
    font-weight: 600;
}

.common-univeresity .blur-grey-txtt {
    font-size: 13px;
    color: #505050;
    margin-bottom: 0;
}

.common-univeresity .blur-blkk-txtt {
    font-size: 13px;
    font-weight: 600;
}

.common-univeresity .orange-btnnn {
    padding: 8px 50px;
}

.common-univeresity .blk-border {
    border-right: 2px solid #000;
    margin: 0px 5px;
}

.common-univeresity .normal-txtt {
    font-size: 13px;
    margin-bottom: 0;
    margin-right: 9px;
    color: #000;
}

.common-univeresity .borderrr {
    border-bottom: 1px solid #000;
}

.common-univeresity .quetion-icon-imgg {
    width: 40px;
    height: 45px;
    margin-right: 15px;
}

.common-univeresity .chair-img {
    width: 20px;
    background: #FFE5C2;
    padding: 5px;
    border-radius: 50px;
}

.common-univeresity .dark-bgg-yellow {
    background: #FFE5C2;
    border-radius: 5px;
    padding: 10px;
}

.common-univeresity .mark-icon {
    width: 16px;
}

.common-univeresity .bold-txtt-placement {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}


.common-univeresity .note-txttt {
    font-size: 15px;
}

.common-univeresity .note-txttt .note-span {
    font-style: italic;
}

.common-univeresity .accordion-item {
    background-color: transparent;
    border: none;
}

.common-univeresity .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: unset;
}

.common-univeresity .accordion-button {
    background: transparent;
    font-size: 15px;
}

.common-univeresity .blue-txtt {
    font-size: 15px;
    color: #0050C8;
    margin-bottom: 0;
    font-weight: 600;
}

.common-univeresity .brown-txtt {
    font-size: 13px;
    color: #707070;
    margin-bottom: 0;
}

.common-univeresity .normal-txttt {
    font-size: 14px;
    margin-bottom: 0;
}

.common-univeresity .blue-txtt-small {
    font-size: 13px;
    font-weight: 600;
    color: #0050C8;
}

.common-univeresity .download-pdf {
    background: #707070;
    color: #FFF;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    padding: 8px 80px;
    transition: border-radius  0.3s ease-in-out;
}

.common-univeresity .download-pdf:hover {
    background: #000;
    border-radius: 15px;
}

.common-univeresity .rating-number {
    font-size: 46px;
    color: #000;
    font-weight: 600;
}


/* fee structure */
.fee-structure .yellow-bgggg {
    padding: 20px 10px 10px 40px;
}

.fee-structure .bold-txtt-blue {
    margin-bottom: 0;
}

.fee-structure .white-bggg-inner {
    padding: 20px 10px 10px 40px;
    box-shadow: unset;
}

.fee-structure .white-back {
    box-shadow: 0px 3px 14px #00000012;
}

.fee-structure .view-course-btnnn {
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 20px;
    color: #202020;
    margin-right: 12px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 15px;
}

.fee-structure .view-course-btnnn:hover {
    background: #FEEDD7;
    border: 0.5px solid #FF9100;
}

