.main-header {
    position: sticky;
    top: 0;
    z-index: 999;
}

.main-header .black-header-top {
    display: flex;
    justify-content: space-between;
    background: #000;
    padding: 8px 40px;
}
.main-header .black-header-top .border-white {
    border-left: 1px solid #FFF;
    height: 25px;
}

.main-header .black-header-top .header-txttt {
    color: #FFF;
    font-size: 14px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-header .black-header-top .header-txttt-icon {
    color: #FFF;
    font-size: 20px;
    margin: 0px 6px;
    cursor: pointer;
}

.main-header .navbar-expand-lg .navbar-collapse {
    justify-content: end;
}

/* Second Header */

.main-header .second-header {
    background: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    padding: 0px 60px;
    margin: 0px 180px;
    box-shadow: 0px 3px 6px #ccc;
}

.main-header .logo-img {
    width: 100%;
    height: 75px;
}

.main-header .second-header .nav-link {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin: 0px 25px;
}


.main-header .second-header .nav-link.active {
    font-weight: 700;
    text-decoration: underline;
}

.main-header .second-header .login-btnnn {
    background: #ABABAB;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 8px 20px;
}

.main-header .second-header .signin-btnnn {
    background: #FF9100;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 8px 10px;
}

.main-header .second-header .profile-btn {
    background: #FF9100;
        border-radius: 50px;
        border: none;
        color: #fff;
        font-size: 14px;
        padding: 8px 10px;
}

.main-header .navbar-toggler {
    font-size: 14px;
    color: #000;
    border: none;
    font-weight: 700;
}

.main-header .mobile-view-divv {
    display: none;
}

.main-header .mobile-view-divv .header-txttt {
    color: #000;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

/* Media Query */

@media(min-width:0px) and (max-width:576px) {
    .main-header .black-header-top {
        display: none;
    }

    .main-header .second-header {
        border-radius: 0px;
        padding: 0px 20px;
        margin: 0px 0px;
    }

    .main-header .logo-img {
        height: 38px;
    }

    .main-header .second-header .nav-link {
        margin: 0px;
        font-size: 12px;
    }

    .main-header .second-header .login-btnnn {
        font-size: 12px;
        padding: 4px 13px;
    }

    .main-header .second-header .signin-btnnn {
        font-size: 12px;
        padding: 4px 13px;
    }

    .main-header .mobile-view-divv {
        display: block;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .main-header .black-header-top {
        display: none;
    }

    .main-header .second-header {
        padding: 0px 20px;
        margin: 0px 0px;
    }

    .main-header .logo-img {
        height: 50px;
    }

    .main-header .second-header .nav-link {
        font-size: 13px;
        margin: 0px 18px;
    }

    .main-header .black-header-top .header-txttt {
        font-size: 13px;
    }

    .main-header .second-header .nav-link {
        margin: 0px;
        font-size: 13px;
    }

    .main-header .second-header .login-btnnn {
        font-size: 12px;
        padding: 4px 13px;
    }

    .main-header .second-header .signin-btnnn {
        font-size: 12px;
        padding: 4px 13px;
    }

    .main-header .mobile-view-divv {
        display: block;
    }

}

@media(min-width:769px) and (max-width:991px) {
    .main-header .second-header {
        padding: 0px 20px;
        margin: 0px 0px;
    }

    .main-header .logo-img {
        height: 50px;
    }

    .main-header .second-header .nav-link {
        font-size: 13px;
        margin: 0px 18px;
    }

    .main-header .black-header-top .header-txttt {
        font-size: 10px;
    }

}

@media(min-width:992px) and (max-width:1024px) {
    .main-header .second-header {
        padding: 0px 20px;
        margin: 0px 80px;
    }

    .main-header .logo-img {
        height: 50px;
    }

    .main-header .second-header .nav-link {
        margin: 0px 18px;
    }

    .main-header .black-header-top .header-txttt {
        font-size: 13px;
    }

    .main-header .second-header .login-btnnn {
        font-size: 13px;
        padding: 6px 16px;
    }

    .main-header .second-header .signin-btnnn {
        font-size: 13px;
        padding: 6px 10px;
    }

    .home-banner .overlay-img {
        width: 100%;
        height: 300px;
    }
}

@media(min-width:1025px) and (max-width:1199px) {

    .main-header .second-header {
        padding: 0px 20px;
        margin: 0px 85px;
    }

    .main-header .logo-img {
        height: 50px;
    }

    .main-header .second-header .nav-link {
        margin: 0px 18px;
    }

    .main-header .black-header-top .header-txttt {
        font-size: 13px;
    }

    .main-header .second-header .login-btnnn {
        font-size: 13px;
        padding: 6px 16px;
    }

    .main-header .second-header .signin-btnnn {
        font-size: 13px;
        padding: 6px 10px;
    }

}

@media(min-width:1200px) and (max-width:1399px) {

    .main-header .second-header {
        padding: 0px 20px;
        margin: 0px 180px;
    }

    .main-header .logo-img {
        height: 50px;
    }

    .main-header .second-header .nav-link {
        margin: 0px 18px;
    }

    .main-header .black-header-top .header-txttt {
        font-size: 13px;
    }

    .main-header .second-header .login-btnnn {
        font-size: 13px;
        padding: 6px 16px;
    }

    .main-header .second-header .signin-btnnn {
        font-size: 13px;
        padding: 6px 10px;
    }

}