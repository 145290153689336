.RecentNews .RecentNews-swiper-holder .RecentNews-swiper-card-holder{
    margin-bottom: 15px;
}

.RecentNews .RecentNews-swiper-holder {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}