.change-pass-modal .women-image {
    width: 300px;
}

.change-pass-modal .deacti-title {
    font-size: 16px;
    color: #202020;
    font-weight: 600;
}

.change-pass-modal label {
    font-size: 14px;
    color: #202020;
}

.change-pass-modal .form-check {
    margin-top: 10px;
}

.change-pass-modal .deacti-subtitle {
    font-size: 14px;
    color: #202020;
}

@media(min-width:0px) and (max-width:576px) {
    .change-pass-modal .women-image {
        width: 150px;
    }

    .change-pass-modal .deacti-title {
        font-size: 14px;
    }

    .change-pass-modal .deacti-subtitle {
        font-size: 12px;
    }

    .change-pass-modal label {
        font-size: 12px;

    }
}


@media(min-width:577px) and (max-width:767px) {
    .change-pass-modal .women-image {
        width: 150px;
    }

    .change-pass-modal .deacti-title {
        font-size: 14px;
    }

    .change-pass-modal .deacti-subtitle {
        font-size: 12px;
    }

    .change-pass-modal label {
        font-size: 12px;

    }
}