.online-mba {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    padding: 25px 15px;
    margin: 5% 0;
}


.online-mba .white-title {
    font-size: 16px;
    font-weight: 600;
}

.online-mba .feture-below p {
    font-size: 14px;
}

.online-mba .feture-below ul li {
    font-size: 14px;
    margin: 0px;
}

.online-mba .feture-below span {
    font-size: 14px;
    color: #0050C8;
    margin: 0px 8px 0px 0px ;
    cursor: pointer;
    font-weight: 600;
}