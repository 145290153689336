.popular-college {
    margin-bottom: 7%;
}

.popular-college .brow-bggg {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000026;
    border-radius: 10px;
    padding: 8px 10px;
}

.popular-college .brow-bggg .uni-img {
    width: 84px;
    height: 52px;
}

.popular-college .brow-bggg .university-name {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
}

.popular-college .brow-bggg .below-txttt {
    font-size: 13px;
    margin: 0px 5px;
}

.popular-college .brow-bggg .blk-border {
    border-right: 2px solid #000;
}

.popular-college .brow-bggg .sub-txtt-main {
    font-size: 13px;
    font-weight: 600;
}

.popular-college .main-class-grey {
    background: #F2F2F2;
}

.popular-college .results-txttt {
    font-size: 14px;
    color: #FFF;
    background: #FF9100 0% 0% no-repeat padding-box;
    border-radius: 5px 0px 0px 5px;
    padding: 10px;
    margin-bottom: 0px;
}


.popular-college .best-course-txtt {
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    margin-bottom: 0px;
}

.popular-college select {
    border: none;
    background: transparent;
    margin-bottom: 0;
}

.download-btn {
    background: transparent;
    border-radius: 4px;
    border: 1px solid #000000;
    font-size: 12px;
    padding: 5px 10px;
}

.download-btn:hover {
    border: 1px solid #FF9100;
    background: #FEEDD7;
}


.compare-btn {
    background: #707070;
    border-radius: 4px;
    border: 1px solid #707070;
    font-size: 12px;
    padding: 5px 10px;
    color: #FFF;
}

.compare-btn:hover {
    background: #FF9100;
    border: 1px solid #FF9100;
}