.RecentNewsCard .RecentNews-swiper-card .main-image .news-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.40;
}

.RecentNewsCard .RecentNews-swiper-card .text-holder h3 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.RecentNewsCard .RecentNews-swiper-card .text-holder p {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
