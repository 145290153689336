.Faq .faq-content-holder {
  margin-bottom: 5%;
  margin-top: 3%;
}

.Faq .faq-content-holder .faq-holder .accordion-item {
  margin-bottom: 5%;
  border: none;
  background: #fff8ee 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.Faq .faq-content-holder .faq-holder .accordion-button {
  background: #fff8ee 0% 0% no-repeat padding-box;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #202020;
}

.Faq .faq-content-holder .faq-holder .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.Faq .faq-content-holder .faq-holder .accordion-button:focus {
  box-shadow: none;
}

.Faq .faq-content-holder .faq-holder .text-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #202020;
}

.Faq .faq-content-holder .faq-holder .text-holder span {
  color: #0050c8;
  cursor: pointer;
}

.Faq .faq-content-holder .faq-holder .text-holder ul li {
  list-style: none;
}

.Faq .faq-content-holder .faq-holder .text-holder ul {
  padding-left: 15px;
}

.Faq .faq-content-holder .faq-holder .accordion-button:not(.collapsed)::after,
.Faq .faq-content-holder .faq-holder .accordion-button.collapsed::after {
  display: none; 
}

.Faq .faq-content-holder .faq-holder .accordion-button .fa-icon {
  margin-left: auto; /* Move the icon to the right */
  transition: transform 0.2s;
}

.Faq .faq-content-holder .faq-holder .accordion-button.collapsed .fa-icon {
  transform: rotate(0deg);
  color: #707070;
}

.Faq
  .faq-content-holder
  .faq-holder
  .accordion-button:not(.collapsed)
  .fa-icon {
  transform: rotate(180deg);
  color: #707070;
}

.Faq .faq-content-holder .Any-Questions .img-holder {
  padding: 0% 25%;
}

.Faq .faq-content-holder .Any-Questions .img-holder .faq-img {
  width: 100%;
  height: 100%;
}

.Faq .faq-content-holder .Any-Questions .text-holder {
  text-align: center;
}

.Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
  font-size: 30px;
  font-weight: 600;
  color: #202020;
  margin: 15px 0px;
}

.Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
  font-size: 16px;
  font-weight: 500;
  color: #202020;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 14px;
  }

  .Faq .faq-content-holder {
    margin-top: 5%;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 12px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 11px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 14px;
  }

  .Faq .faq-content-holder {
    margin-top: 5%;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 14px;
  }

  .Faq .faq-content-holder {
    margin-top: 5%;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 15px;
  }

  .Faq .faq-content-holder {
    margin-top: 5%;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Faq .faq-content-holder {
    margin-top: 5%;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }
 

  .Faq .faq-content-holder .faq-holder .accordion-button {
    font-size: 14px;
  }

  .Faq .faq-content-holder .faq-holder .text-holder p {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    font-size: 18px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-subheading {
    font-size: 12px;
  }

  .Faq .faq-content-holder .Any-Questions .text-holder .faq-heading {
    margin: 5px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Faq .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }
}
