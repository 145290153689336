.main-footer {
    background: #202020;
    padding-bottom: 15px;
}

.main-footer .footer-bggg {
    background: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    padding: 0px 30px;
    margin: 0px 100px;
    margin: 0px 180px;
    border-top: 1px solid #ccc;
}

.main-footer .footer-bggg .logo-img {
    width: 100%;
    height: 89px;
}

.main-footer .footer-bggg .footer-txttt-white {
    font-size: 14px;
    color: #000;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-footer .font-icon {
    color: #E28C2D;
    margin-right: 10px;
}

.main-footer .footer-txttt {
    font-size: 15px;
    color: #FFFFFF;
}

.main-footer .footer-social-white-icon {
    color: #FFFFFF;
    border-radius: 50px;
    margin: 5px;
    font-size: 23px;
}

.main-footer .term-txttt {
    color: #FF9100;
    font-size: 13px;
    margin-bottom: 0;
}

.main-footer .orange-border {
    border-right: 1px solid #FF9100;
}
@media(min-width:0px) and (max-width:576px) {
    .main-footer .footer-txttt {
        font-size: 12px;
    }

    .main-footer .term-txttt {
        font-size: 12px;
    }

    .main-footer .footer-bggg {
        display: none;
    }

   
}

@media(min-width:577px) and (max-width:768px) {
    .main-footer .footer-bggg {
        display: none;
    }

  
}


@media(min-width:768px) and (max-width:991px) {
    .main-footer .footer-bggg {
        margin: 0px 0px;
        padding: 5px 0px;
    }

    .main-footer .footer-bggg .logo-img {
        height: 60px;
    }
    }

@media(min-width:992px) and (max-width:1023px) {
    .main-footer .footer-bggg {
        margin: 0px 80px;
        padding: 5px 0px;
    }

.main-footer .footer-bggg .logo-img {
    height: 65px;
}
}

@media(min-width:1024px) and (max-width:1199px) {
    .main-footer .footer-bggg {
        margin: 0px 85px;
        padding: 5px 0px;
    }

.main-footer .footer-bggg .logo-img {
    height: 65px;
}
}

@media(min-width:1200px) and (max-width:1399px) {
    .main-footer .footer-bggg {
        padding: 5px 0px;
    }

.main-footer .footer-bggg .logo-img {
    height: 60px;
}
}