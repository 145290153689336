.common-btn-holder .send-btn {
  background: #202020;
  border: 1px solid #202020;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding: 8px 30px;
}

.common-btn-holder .send-btn:hover {
  background: #ff9100;
  border: 1px solid #ff9100;
}
.common-btn-holder .send-btn:active {
  background: #ff9100 !important;
  border: 1px solid #ff9100 !important;
}

.common-btn-holder .Submit-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 5px 30px;
}

.common-btn-holder .Submit-btn:hover {
  background: #101010 0% 0% no-repeat padding-box;
  border: 1px solid #000;
  border-radius: 3px;
  color: #fff;
}

.common-btn-holder .Submit-btn:active {
  background: #101010 0% 0% no-repeat padding-box !important;
  border: 1px solid #000 !important;
  border-radius: 3px;
  color: #fff;
}

@media (min-width: 0px) and (max-width: 320px) {
  .common-btn-holder .send-btn {
    padding: 3px 20px;
  }
}
