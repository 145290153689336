.home-banner {
    position: relative;
    top: -102px;
}

.home-banner .search-fieldd {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-banner .search-fieldd .nav-pills .nav-link {
    color: #FFF;
    font-size: 15px;
}

.home-banner .search-fieldd .nav-pills .nav-link.active {
    text-decoration: underline;
    background: transparent;
}

.search-fieldd .form-control {
    font-size: 16px;
    color: #7A7A7A;
    padding: 11px 13px;
    background: #F5F5F5;
}

.search-fieldd .input-group-text {
    background: #FF9100;
    border-radius: 20px 5px 5px 20px;
    padding: 13px;
    font-size: 25px;
    cursor: pointer;
}

.home-banner .banner-overlay-bottom-up {
    position: absolute;
    left: 0;
    bottom: 0;
}

.home-banner .banner-overlay-top-up {
    position: absolute;
    right: 0;
    top: 0;
}


.home-banner .overlay-img {
    width: 100%;
    height: 400px;
}


/* Media Query */

@media(min-width:0px) and (max-width:576px) {
    .home-banner .banner-overlay-bottom-up {
        display: none;
    }

    .home-banner .banner-overlay-top-up {
        display: none;
    }

    .search-fieldd .form-control {
        font-size: 12px;
        color: #7A7A7A;
        padding: 4px 7px;
    }

    .search-fieldd .input-group-text {
        padding: 3px;
        font-size: 15px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link {
        font-size: 10px;
        padding: 0px 4px;
    }

    .home-banner {
        top: 0px;
    }

    .home-banner .search-fieldd {
        position: absolute;
        top: 54%;
        left: 50%;
    }

}


@media(min-width:577px) and (max-width:768px) {
    .home-banner .banner-overlay-bottom-up {
        display: none;
    }

    .home-banner .banner-overlay-top-up {
        display: none;
    }

    .search-fieldd .form-control {
        font-size: 14px;
        color: #7A7A7A;
        padding: 4px 10px;
    }

    .search-fieldd .input-group-text {
        padding: 7px;
        font-size: 21px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link {
        font-size: 12px;
        padding: 0px 7px;
    }

    .home-banner .search-fieldd {
        top: 77%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .home-banner .banner-overlay-bottom-up {
        display: none;
    }

    .home-banner .banner-overlay-top-up {
        display: none;
    }

    .search-fieldd .form-control {
        font-size: 14px;
        color: #7A7A7A;
        padding: 4px 10px;
    }

    .search-fieldd .input-group-text {
        padding: 7px;
        font-size: 21px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link {
        font-size: 12px;
        padding: 0px 7px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link.active {
        font-size: 12px;
        padding: 0px 7px;
    }

    .home-banner .search-fieldd {
        top: 77%;
    }
}

@media(min-width:993px) and (max-width:1024px) {
    .home-banner .overlay-img {
        width: 100%;
        height: 300px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link {
        font-size: 13px;
        padding: 0px 7px;
    }

    .home-banner .search-fieldd .nav-pills .nav-link.active {
        font-size: 12px;
        padding: 0px 7px;
    }

    .search-fieldd .form-control {
        font-size: 14px;
        color: #7A7A7A;
        padding: 4px 10px;
    }

    .search-fieldd .input-group-text {
        padding: 7px;
        font-size: 21px;
    }

}

@media(min-width:1025px) and (max-width:1399px) {
    .home-banner .overlay-img {
        width: 100%;
        height: 300px;
    }



}