/* CollegeStep css started */

.CollegeStep {
    margin: 5% 0;
}

.CollegeStep .banner-image {
    position: relative;
}

.CollegeStep .banner-image img.banner2 {
    width: 100%;
    height: 100%;
}

.CollegeStep .banner-image .overlay {
    background: #0000 linear-gradient(180deg, #00000085, #0000007a 40%) 0 0 no-repeat padding-box;
    border-radius: 11px 11px 0 0;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0px;
    width: 100%;
}

.CollegeStep .content {
    position: absolute;
    top: 0px;
}

.CollegeStep .main-step-below-section {
    position: absolute;
    bottom: -65px;
    left: 15%;
}

.CollegeStep .content .heading {
    color: white;
    margin-top: 10%;
}

.CollegeStep .white-bgg {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px 10px 5px 10px;
    display: block;
    transition: 1.3s;
}

.CollegeStep .white-bgg:hover {
    transform: scale(1.1);
}

.CollegeStep .white-bgg .white-bgg-title {
    font-size: 26px;
    color: #C9C9C9;
    font-weight: 600;
}

.CollegeStep .white-bgg:hover .white-bgg-title {
    color: #707070;
}

.CollegeStep .white-bgg .select-txttt {
    font-size: 18px;
    font-weight: 600;
}

.CollegeStep .border-bottom-orange {
    border-bottom: 3px solid #FF9100;
    margin-top: 10px;
}

.CollegeStep .onesteppp {
    font-size: 30px;
    color: #FF9100;
    font-weight: 700;
}

.CollegeStep .sub-text {
    color: #FFF;
    margin-top: 10%;
}


/* media query */

@media (min-width:0px) and (max-width:576px) {
    .CollegeStep .main-step-below-section {
        position: unset;
    }

    .CollegeStep .banner-image img.banner2 {
        display: none;
    }

    .CollegeStep .content {
        position: unset;
    }

    .CollegeStep .onesteppp {
        font-size: 22px;
        text-align: center;
    }

    .CollegeStep .content .heading {
        color: #000;
        text-align: center;
    }

    .CollegeStep .sub-text {
        color: #000;
        margin-top: 2%;
        text-align: center;
    }
}


@media (min-width:577px) and (max-width:768px) {
    .CollegeStep .main-step-below-section {
        position: unset;
        margin-top: 5%;
    }

    .CollegeStep .onesteppp {
        font-size: 25px;
    }

    .CollegeStep .white-bgg .select-txttt {
        font-size: 14px;
    }

    .CollegeStep .white-bgg .white-bgg-title {
        font-size: 24px;
    }

    .CollegeStep .sub-text {
        margin-top: 4%;
        font-size: 16px;
    }

    .IndustryExpert {
        margin: 18% 0 5% 0;
    }
}


@media (min-width:769px) and (max-width:1024px) {
    .CollegeStep .main-step-below-section {
        position: unset;
        margin-top: 5%;
    }

    .CollegeStep .white-bgg .select-txttt {
        font-size: 18px;
    }
}




@media (min-width:1025px) and (max-width:1440px) {
    .CollegeStep .main-step-below-section {
        left: 2%;
    }

    .CollegeStep .white-bgg .white-bgg-title {
        font-size: 22px;
    }
}