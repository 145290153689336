.Breadcrumb_holder {
  justify-content: center;
  margin-top: -5%;
}

.Breadcrumb_holder ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Breadcrumb_holder li {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

/* Add separator */
.Breadcrumb_holder li + li::before {
  content: ">";
  margin: 0 8px; /* Adjust spacing as needed */
  color: #000; /* Color of the separator */
}

.Breadcrumb_holder li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

.Breadcrumb_holder li a:hover {
  text-decoration: underline;
}


@media(min-width:0px) and (max-width:767px) {

    .Breadcrumb_holder {
        margin-top: 5%;
    }

    .Breadcrumb_holder li {
        font-size: 12px;
    }
}