.modal-class .modal-titleee {
    color: #000;
    font-size: 24px;
    font-weight: 700;
}

.modal-class .modal-body {
    background: #F9F9F9;
    border-radius: 10px;
    padding: 60px 20px;
}

.modal-class .modal-txttt {
    font-size: 14px;
}


/* Media */

@media(min-width:0px) and (max-width:576px) {
    .modal-class .modal-titleee {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }

    .modal-class .modal-body {
        background: #F9F9F9;
        border-radius: 10px;
        padding: 20px;
    }

}

@media(min-width:577px) and (max-width:768px) {
    .modal-class .modal-titleee {
        color: #000;
        font-size: 22px;
        font-weight: 700;
    }

    .modal-class .modal-body {
        background: #F9F9F9;
        border-radius: 10px;
        padding: 40px;
    }

}