.Universities_Modal .Universities-slider-section-holder .Universities-slider {
    padding: 15px 5px;
}

.Universities_Modal .modal-content {
    border-radius: 10px;
}

.fade.Universities_Modal.modal.show {
    background: #ffffff61 0% 0% no-repeat padding-box;
    backdrop-filter: blur(15px);
}