.Universities_card-holder {
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  padding: 25px 20px;
  transition: all 0.3s ease-in-out;
}


.Universities_card-holder:hover {
   margin-top: -10px;
   transition: all 0.3s ease-in-out;
}


.Universities_card-holder .Universities_card .university-logo-image-holder {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 20px 21px 0px 21px;
}


.Universities_card-holder
  .Universities_card
  .university-image-holder {
  aspect-ratio: 1/0.5;
}

.Universities_card-holder
  .Universities_card
  .university-image-holder
  .university-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.Universities_card-holder
  .Universities_card
  .university-logo-image-holder
  .university-logo-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.3;
}
