

.Contact_us .Contact_us_holder .commonbanner {
  z-index: -1;
}

.Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
  font-size: 24px;
  text-align: center;
}

.Contact_us .Contact_us_holder .Contact_us_form_holder {
  margin-top: -10%;
  margin-bottom: 5%;
}

.Contact_us .Contact_us_holder .Contact_us_form_holder .Contact_us_form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000002b;
  padding: 35px 30px;
  border-radius: 15px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 10px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder {
    margin-top: 5%;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder .Contact_us_form {
    padding: 18px 15px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder .send-btn {
    font-size: 11px;
    padding: 5px 8px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 14px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder {
    margin-top: 5%;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder .Contact_us_form {
    padding: 18px 15px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder .send-btn {
    font-size: 11px;
    padding: 5px 8px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 14px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder {
    margin-top: 5%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 15px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder {
    margin-top: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Contact_us .Contact_us_holder .Contact_us_form_holder {
    margin-top: 5%;
  }

  .Contact_us .Contact_us_holder .commonbanner .title-banner {
    top: 60%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Contact_us .Contact_us_holder .commonbanner .title-banner {
    top: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Contact_us .Contact_us_holder .commonbanner .title-banner {
    top: 60%;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Contact_us .Contact_us_holder .commonbanner .title-banner .banner-titleee {
    font-size: 18px;
  }

  .Contact_us .Contact_us_holder .commonbanner .title-banner {
    top: 60%;
  }
}
