.form-holder .form-control {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
}

.form-holder .form-control:focus {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  color: #000;
}

.form-holder .phone-input-button {
  border-right: 5px solid #fff;
  flex-shrink: 0 !important;
  margin-right: 10px !important;
  padding: 0 30px !important;
}

.form-holder .react-tel-input .form-control {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  outline: none;
  padding: 7.5px 16px 9.5px 130px !important;
  transition: box-shadow 0.25s ease, border-color 0.25s ease;
  width: 100%;
  background: #f3f3f3 0% 0% no-repeat padding-box;
}

.form-holder .react-tel-input .form-control:focus {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  outline: none;
  padding: 7.5px 16px 9.5px 130px !important;
  transition: box-shadow 0.25s ease, border-color 0.25s ease;
  width: 100%;
  background: #f3f3f3 0% 0% no-repeat padding-box;
}

@media (min-width: 0px) and (max-width: 320px) {
  .form-holder .form-control {
    font-size: 12px;
  }

  .form-holder .react-tel-input .form-control {
    font-size: 12px;
  }
  
}

@media (min-width: 321px) and (max-width: 485px) {
  .form-holder .form-control {
    font-size: 12px;
  }

  .form-holder .react-tel-input .form-control {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .form-holder .form-control {
    font-size: 12px;
  }

  .form-holder .react-tel-input .form-control {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .form-holder .form-control {
    font-size: 12px;
  }
  
  .form-holder .react-tel-input .form-control {
    font-size: 12px;
  }
}
