@import url('https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins' !important;
}

.form-control:focus {
  box-shadow: none !important;
}

/* swiper bullet css */

.swiper .swiper-pagination-bullet-active {
  border-radius: 50px;
  height: 4px;
  width: 26px;
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ff9100;
}

.swiper span.swiper-pagination-bullet {
  border-radius: 50px;
  height: 4px;
  width: 26px;
  background: #ff9100 0% 0% no-repeat padding-box;
  border-radius: 50px;
}


/* Pagination Common CSS */

.main-pagination {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  text-align: center;
}


.page-link {
  background: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #000 !important;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 10px;
}


.page-item.active .page-link {
  background: #FF9100;
  border: 1px solid #707070;
  border-radius: 5px;
  border: 1px solid #FF9100;
  margin: 0px 10px;
}

.heading {
  font-size: 26px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 600;
}

.text {
  font-size: 13px;
  color: #FF9100;
  font-weight: bold;
  font-family: "Roboto";
}

.sub-text {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
}

.sub-text-main {
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto";
}

.text-main {
  color: #202020;
  font-size: 15px;
  font-family: "Roboto";
}

.bold-noraml-txtt {
  color: #202020;
  font-size: 15px;
  font-weight: 600;
}

.orange-btnnn {
  background: #FF9100;
  border-radius: 5px;
  border: 1px solid #FF9100;
  color: #fff;
  font-size: 14px;
  padding: 8px 10px;
}

.orange-btnnn:hover {
  background: #202020;
  border: 1px solid #202020;
}


.white-btnnn {
  background: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 14px;
  padding: 8px 40px;
  color: #202020;
  margin-right: 12px;
  width: fit-content;
}

.white-btnnn:hover {
  background: #202020;
  color: #FFF;
}

/* Commom media query */

@media (min-width:0px) and (max-width:485px) {
  .heading {
    font-size: 18px;
  }

  .sub-text {
    font-size: 14px;
  }

  .sub-text-main {
    font-size: 14px;
  }

  .white-btnnn {
    font-size: 12px;
    padding: 5px 15px;
  }

  .orange-btnnn {
    font-size: 12px;
    padding: 5px 15px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .heading {
    font-size: 20px;
  }

  .sub-text {
    font-size: 16px;
  }

  .sub-text-main {
    font-size: 16px;
  }

  .white-btnnn {
    font-size: 12px;
    padding: 5px 15px;
  }

  .orange-btnnn {
    font-size: 12px;
    padding: 5px 15px;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .heading {
    font-size: 22px;
  }

  .sub-text {
    font-size: 16px;
  }

  .sub-text-main {
    font-size: 18px;
  }

  .white-btnnn {
    font-size: 12px;
    padding: 5px 20px;
  }

  .orange-btnnn {
    font-size: 12px;
    padding: 5px 20px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .heading {
    font-size: 24px;
  }

  .sub-text {
    font-size: 18px;
  }

  .white-btnnn {
    font-size: 13px;
    padding: 5px 20px;
  }

  .orange-btnnn {
    font-size: 13px;
    padding: 5px 20px;
  }
}