.pg-cources .cources-tablist  .nav-link {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  padding: 0px 60px 0px 0px;
  margin: 0;
  border-radius: 0;
}

.pg-cources .cources-tablist {
  display: flex;
}

.pg-cources .cources-tablist .nav-link.active {
  font-weight: 700;
  color: #000;
  background: transparent;
  position: relative;
}

.pg-cources .cources-tablist .nav-link.active:after {
  content: "";
  bottom: 0px;
  width: 20px;
  border-bottom: 2px solid #ff9100;
  position: absolute;
  left: 0px;
}

.innercources .trending-cource-card .download-brochure {
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 11px;
  padding: 10px;
  color: #202020;
  margin-right: 12px;
}
.innercources .trending-cource-card .compare-college {
  background: #707070;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 11px;
  padding: 10px;
  color: #fff;
}


@media (min-width: 0px) and (max-width: 576px) {
  .main-search-field {
    top: 87%;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .pg-cources .cources-tablist {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .pg-cources .cources-tablist::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
  .pg-cources .cources-tablist::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  /* Track */
  .pg-cources .cources-tablist::-webkit-scrollbar-track {
    background: #e8e8e8;
  }
  .pg-cources .cources-tablist::-webkit-scrollbar-track:horizontal {
    background: #e8e8e8;
  }
  /* Handle */
  .pg-cources .cources-tablist::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 5px;
  }
  .pg-cources .cources-tablist::-webkit-scrollbar-thumb:horizontal {
    background: #c7c7c7;
    border-radius: 5px;
  }

  .pg-cources .cources-tablist  .nav-link {
    padding: 0px 30px 0px 0px;
    font-size: 16px;
  }

}
