.schedule-call {
    margin: 0% 0% 5% 0;
}

.schedule-call .confused-txttt {
    font-size: 18px;
    color: #202020;
    font-weight: 700;
}

.schedule-call .schedule-call-btn {
    background: #FF9100;
    border-radius: 4px;
    font-size: 14px;
    color: #FFF;
    border: none;
    padding: 10px 20px;
}

.schedule-call .schedule-call-btn:hover {
    background: #202020;
}

/* Media Query */

@media(min-width:0px) and (max-width:576px) {
    .schedule-call .confused-txttt {
        font-size: 15px;
    }

    .schedule-call .schedule-call-btn {
        font-size: 11px;
        padding: 10px 11px;
    }
}