.common-univeresity .grey-bggg {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 10px;
    padding: 25px 15px;
}

.common-univeresity .blod-normal-txtt {
    font-weight: 600;
    font-size: 14px;
}

.common-univeresity .blk-border {
    border-bottom: 1px solid #ccc;
}

.common-univeresity .verfied-txttt {
    font-size: 14px;
    color: #008B1C;
    font-weight: 600;
}

.common-univeresity .verify-img {
    width: 15px;
}

.common-univeresity .grey-bg {
    background: #EEEEEE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 10px 5px;
}

.common-univeresity .user-icon {
    font-size: 20px;
    color: #707070;
}

.common-univeresity .read-more-btn {
    border: none;
    background: #BBBBBB;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    padding: 5px 10px;
}

.common-univeresity .read-more-btn:hover {
    background: #202020;
    color: #FFF;
}