.overview-first-section {
    margin-top: 2%;
}

.overview-first-section .girls-img {
    width: 100%;
}

.overview-first-section {
    background: #FFF8EE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    padding: 20px 20px 40px 20px;
}


.overview-first-section .txttt p {
    font-size: 14px;
    color: #000;
    text-align: justify;
}


/* Media */

@media(min-width:0px) and (max-width:576px) {
    .overview-first-section .txttt p {
        font-size: 12px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .overview-first-section .txttt p {
        font-size: 13px;
    }
}