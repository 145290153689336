.trending-cources .trending-cource-card {
    background: #FFFFFF;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 25px 25px 20px 25px;
}

.trending-cources .trending-cource-card .main-img-class {
    position: relative;

}

.trending-cources .trending-cource-card .main-img-class .trending-img {
    width: 100%;
    height: 100%;
}

.trending-cources .trending-cource-card .university-txtt {
    font-size: 14px;
    font-weight: 600;
    color: #646464;
    margin-top: 8px;
}

.trending-cources .trending-cource-card .course-name {
    font-size: 16px;
    color: #000000;
    font-weight: 700;
}

.trending-cources .trending-cource-card .download-brochure {
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    color: #202020;
    margin-right: 12px;
}

.trending-cources .trending-cource-card .download-brochure:hover {
    background: #FEEDD7;
    border: 1px solid #FF9100;
}

.trending-cources .trending-cource-card .compare-college {
    background: #707070;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    color: #FFF;
}

.trending-cources .trending-cource-card .compare-college:hover {
    background: #FF9100;
    border: 1px solid #FF9100;
}

.trending-cources .trending-cource-card .main-img-class .offer-text {
    padding: 0px 8px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px 17px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px 0px 5px 0px;
    color: #ff9100;
    font-size: 14px;
}

.trending-cources .trending-cource-card .main-img-class .white-bgggg {
    position: absolute;
    left: 10px;
    bottom: -27px;
    padding: 8px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.trending-cources .trending-cource-card .main-img-class .white-bgggg .uni-img {
    width: 64px;
    height: 40px;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .trending-cources {
        margin: 10% 0;
    }

    .trending-cources .trending-cource-card .main-img-class .offer-text {
        font-size: 12px;
    }

    .trending-cources .trending-cource-card .main-img-class .white-bgggg .uni-img {
        width: 40px;
        height: 20px;
    }

    .trending-cources .trending-cource-card .main-img-class .white-bgggg {
        position: absolute;
        left: 4px;
        bottom: 4px;
        padding: 5px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 5px;
    }

    .trending-cources .trending-cource-card .university-txtt {
        font-size: 12px;
    }

    .trending-cources .trending-cource-card .course-name {
        font-size: 14px;
    }

    .trending-cources .trending-cource-card .download-brochure {
        font-size: 12px;
        padding: 8px;
    }

    .trending-cources .trending-cource-card .compare-college {
        font-size: 12px;
        padding: 8px 15px;
        margin-top: 10px;
    }
}


@media(min-width:577px) and (max-width:768px) {
    .trending-cources {
        margin: 8% 0;
    }

    .trending-cources .trending-cource-card .main-img-class .offer-text {
        font-size: 13px;
    }

    .trending-cources .trending-cource-card .main-img-class .white-bgggg .uni-img {
        width: 40px;
        height: 20px;
    }

    .trending-cources .trending-cource-card .main-img-class .white-bgggg {
        position: absolute;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 5px;
    }

    .trending-cources .trending-cource-card .university-txtt {
        font-size: 12px;
    }

    .trending-cources .trending-cource-card .course-name {
        font-size: 14px;
    }

    .trending-cources .trending-cource-card .download-brochure {
        font-size: 12px;
        padding: 8px;
        margin-right: 5px;
    }

    .trending-cources .trending-cource-card .compare-college {
        font-size: 12px;
        padding: 8px 15px;
        margin-top: 10px;
    }
}