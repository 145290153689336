.succeeful-modal-class .women-image {
    width: 100%;
}

.succeeful-modal-class .modal-body {
    padding: 40px 20px;
}

.succeeful-modal-class .deacti-title {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}

.succeeful-modal-class .deacti-subtitle {
    font-size: 14px;
    color: #131313;
}