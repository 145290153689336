.course-deatils-tab .nav-pills .nav-link.active {
    color: #FF9100;
    border-radius: 0px;
    text-decoration: underline;
    background: transparent;
}

.course-deatils-tab .nav-pills .nav-link {
    color: #202020;
    font-size: 14px;
    background: transparent;
    border-radius: 0px;
}

.course-deatils-tab .details-tab-title {
    font-size: 20px;
    color: #202020;
    font-weight: 600;
}

.course-deatils-tab .blk-borderrr {
    border-bottom: 1px solid #707070;
    margin: 2% 0%;
}