.university-second .trending-cource-card .main-img-class .white-bgggg {
    display: none;
}

.university-second .trending-cource-card .course-name {
    text-align: start;
}

.university-second .trending-cource-card .download-brochure {
    font-size: 10px;
}

.university-second .trending-cource-card .compare-college {
    font-size: 10px;
}

.university-second .back-btnnn {
    border: none;
    background: transparent;
    color: #000;
    font-size: 14px;
}

.university-second .back-btnnn:hover {
    text-decoration: underline;
    color: #FF9100;
}

.university-second .title-txtttt {
    font-size: 32px;
    font-weight: 600;
    color: #000;
}

.university-second .border-orange {
    border-bottom: 2px solid #FF9100;
}