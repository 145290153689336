.PrivacyPolicy .PrivacyPolicy_holder {
  background: #fff8ee 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  padding: 30px 40px;
  margin-bottom: 5%;
  margin-top: 3%;
}

.PrivacyPolicy .PrivacyPolicy_holder .text-holder {
  margin-bottom: 40px;
}

.PrivacyPolicy .PrivacyPolicy_holder .text-holder .PrivacyPolicy_heading_text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #202020;
}

.PrivacyPolicy .PrivacyPolicy_holder .text-holder .PrivacyPolicy_content_text {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  color: #202020;
}

.PrivacyPolicy .PrivacyPolicy_holder .text-holder .starting-space {
  margin-left: 2%;
}

.PrivacyPolicy .PrivacyPolicy_holder .text-holder li {
  list-style: lower-alpha;
}


/* Media Query */

@media(min-width:0px) and (max-width:576px) {
  .PrivacyPolicy .PrivacyPolicy_holder .text-holder .PrivacyPolicy_content_text {
    font-size: 13px;
  }
}

@media(min-width:577px) and (max-width:767px) {
  .PrivacyPolicy .PrivacyPolicy_holder .text-holder .PrivacyPolicy_content_text {
    font-size: 14px;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .PrivacyPolicy .PrivacyPolicy_holder .text-holder .PrivacyPolicy_content_text {
    font-size: 14px;
  }
}
