.courcesbanner .banner-img {
    width: 100%;
}

.courcesbanner {
    position: relative;
}

.main-search-field {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}