.change-pass-modal .modal-body {
    padding: 0px 100px 40px 100px;
}

.change-pass-modal .orange-btnnn {
    padding: 8px 50px;
}

.change-pass-modal .modal-header {
    padding: 40px 100px;
    border-bottom: none;
    margin-bottom: 0px;
}

.change-pass-modal .modal-header-class {
    font-size: 22px;
    color: #131313;
    font-weight: 600;
}

.change-pass-modal .modal-header .btn-close {
    background-image: url('../../../../../public/assests/Images/icon/Group\ 37954.png');
    border-radius: 50px;
    font-size: 18px;
}


.change-pass-modal .password-input {
    position: relative;
}

.change-pass-modal .password-input .form-control {
    padding-right: 40px;
}

.change-pass-modal .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #707070;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .change-pass-modal .modal-header {
        padding: 10px;
    }

    .change-pass-modal .modal-body {
        padding: 10px 20px;
    }

    .change-pass-modal .orange-btnnn {
        padding: 5px 25px;
    }

    .change-pass-modal .modal-header-class {
        font-size: 18px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .change-pass-modal .modal-header {
        padding: 10px;
    }

    .change-pass-modal .modal-body {
        padding: 10px 20px;
    }

    .change-pass-modal .orange-btnnn {
        padding: 5px 25px;
    }

    .change-pass-modal .modal-header-class {
        font-size: 20px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .change-pass-modal .modal-header {
        padding: 20px;
    }

    .change-pass-modal .modal-body {
        padding: 10px 40px;
    }

    .change-pass-modal .orange-btnnn {
        padding: 5px 25px;
    }

    .change-pass-modal .modal-header-class {
        font-size: 20px;
    }
}