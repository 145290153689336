/* LatestNews css started */
.LatestNews {
    margin: 5% 0; 
}
.LatestNews .late-main {
    padding: 13px 12px;
    position: relative;
    width: 100%;
    background: #fff6eb 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 10px;
}
.LatestNews .late-main .main-image img.image-lates {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.LatestNews .late-main .main-image {
    width: 100%;
    height: 167px;
}

.LatestNews .late-main .img-holder{
    position: relative;
}


.LatestNews .late-main .img-holder .offer {
    padding: 0px 8px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 5px 17px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50px 0px 8px 0px;
    color: #ff9100;
}
.LatestNews .late-main p.sub-text {
    margin-top: 5%;
}
.LatestNews .swiper {
    padding-top: 1%;
    padding-bottom: 3%;
}
.LatestNews .swiper  .swiper-pagination-bullet-active {
    border-radius: 50px;
    height: 4px;
    width: 26px;
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #ff9100;
}
.LatestNews .swiper  span.swiper-pagination-bullet {
    border-radius: 50px;
    height: 4px;
    width: 26px;
    background: #ff9100 0% 0% no-repeat padding-box;
    border-radius: 50px;
}

.LatestNews a {
    color: #000;
}

/* Media Query started */

@media (min-width:0px) and (max-width:485px) {
    .LatestNews .swiper {
       padding-bottom: 11%;
    }
    .LatestNews .late-main .offer {
      top: 151px;
    }
}
@media (min-width:486px) and (max-width:575px) {
.LatestNews .swiper {
    padding-top: 1%;
    padding-bottom: 8%;
}
}
@media (min-width:576px) and (max-width:767px) {
    .LatestNews .swiper {
        padding-top: 1%;
        padding-bottom: 8%;
    }
    }
    @media (min-width:768px) and (max-width:991px) {
        .LatestNews .swiper {
            padding-top: 1%;
            padding-bottom: 6%;
        }
        }