.course-syllabus {
    margin: 5% 0;
}

.course-syllabus .weeks-txtt {
    font-size: 13px;
    color: #202020 ;
    font-weight: 400;
    margin-bottom: 0;
    padding: 10px;
}

.course-syllabus .weeks-txtt-2 {
    font-size: 13px;
    color: #202020;
    font-weight: 400;
    margin-bottom: 0;

}


.course-syllabus .weeks-txtt-4 {
    font-size: 25px;
    color: #202020;
    font-weight: 600;
    margin-bottom: 0;
    padding: 10px;
}

.course-syllabus .weeks-txtt-3 {
    font-size: 18px;
    color: #202020;
    font-weight: 400;
    margin-bottom: 0;
    border-left: 2px solid #FF9100;
    padding: 0px 5px 0px 5px;
}

.course-syllabus .credit-img {
    width: 30px;
}

.course-syllabus .faq-content-holder .faq-holder .accordion-button:not(.collapsed)::after,
.course-syllabus .faq-content-holder .faq-holder .accordion-button.collapsed::after {
    display: block;
    color: #000;
}


.course-syllabus .faq-content-holder .faq-holder .accordion-item {
    margin-bottom: 3%;
    border: none;
    background: #fff8ee 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}

.course-syllabus .faq-content-holder .faq-holder .accordion-button {
    background: #fff8ee 0% 0% no-repeat padding-box;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: #202020;
}

.course-syllabus .accordion-body {
   background-color: #fff;
} 

.course-syllabus .dashed-border {
    border-bottom: 2px dashed #ccc;
    margin-top: 13px;
}