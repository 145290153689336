.filter-tab .filter-icon {
    width: 18px;
    height: 18px;
}

.filter-tab .blk-border {
    border-bottom: 1px solid #ccc;
    margin: 7px 0px;
}

.filter-tab .label {
    font-size: 15px;
}

.filter-tab .clear-all-tab {
    background: #F2F2F2;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
}

.filter-tab .clear-all-txtt {
    margin-bottom: 0;
    font-size: 14px;
}

.filter-tab .clear-all-txtt-span {
    margin-bottom: 0;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.filter-tab .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #FFF7EC;
    box-shadow: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.filter-tab .accordion .accordion-button {
    background: #FFF7EC;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px 10px 15px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: none;
    border: none;
}


.filter-tab .accordion-button:not(.collapsed)::after {
    color: #000;
}

.filter-tab .accordion-body {
    background: #F5F5F5;
    padding: 10px;
    overflow-y: scroll;
    height: 200px;
    scrollbar-width: 5px;
    -ms-overflow-style: none;
}

.filter-tab .accordion-body::-webkit-scrollbar {
    display: none;
}

.filter-tab .input-group-text {
    background: #FFF;
    border: none;
}

.filter-tab .below-section .form-control {
    border: none;
}


.filter-tab .accordion-button:not(.collapsed)::after {
    background-image: url('../../../../../public/assests/Images/icon/arrow.png');
    background-size: 10px;
    background-repeat: no-repeat;
}

.filter-tab .accordion-button::after {
    content: '';
    display: inline-block;
    background-image: url('../../../../../public/assests/Images/icon/arrow.png');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
}

.filter-tab .form-control {
    font-size: 14px;
    border: 1px solid #707070;
    color: #707070;
}