.Top_Approved .Top_Approved-holder {
  margin: 5% 0%;
}

.Top_Approved .Top_Approved-holder .heading-holder .heading-text {
  text-align: left;
  font-size: 26px;
}

.Top_Approved .Top_Approved-holder .heading-holder .normal-heading {
  font-weight: 600;
  color: #000000;
}

.Top_Approved .Top_Approved-holder .heading-holder .bold-heading {
  font-weight: 700;
  color: #000000;
}

.Top_Approved .Top_Approved-holder .heading-holder .color-heading {
  font-weight: 600;
  color: #ee9723;
  width: fit-content;
}

.Top_Approved .Top_Approved-holder .heading-holder .color-heading:after {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid #ee9723;
  margin-top: 15px;
}

.Top_Approved .Top_Approved-holder .Approved-text-holder p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.Top_Approved .Top_Approved-holder .university-logo-slider-holder {
  height: 290px;
}

.Top_Approved .Top_Approved-holder .university-logo-slider-holder .swiper {
  width: 100%;
  height: 100%;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .swiper-wrapper {
  height: 250px;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .logo-slider
  .img-holder
  .university-logo-img {
  height: 60%;
  width: 60%;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .logo-slider
  .swiper-button-prev {
  bottom: 0px;
  top: auto;
  left: 48%;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .logo-slider
  .swiper-button-next {
  bottom: 0px;
  top: auto;
  left: 52%;
}

.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .logo-slider
  .swiper-button-prev:after,
.Top_Approved
  .Top_Approved-holder
  .university-logo-slider-holder
  .logo-slider
  .swiper-button-next:after {
  font-size: 15px;
  color: #000;
  font-weight: 800;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 80%;
    width: 80%;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 150px;
  }

  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 200px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 65%;
    width: 65%;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 150px;
  }

  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 200px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 65%;
    width: 65%;
  }
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 200px;
  }
  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 230px;
    margin-top: 13px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 65%;
    width: 65%;
  }
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 200px;
  }
  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 230px;
    margin-top: 13px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 80%;
    width: 80%;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 150px;
  }

  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 200px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Top_Approved .Top_Approved-holder .heading-holder .heading-text {
    text-align: left;
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 80%;
    width: 80%;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .swiper-wrapper {
    height: 150px;
  }

  .Top_Approved .Top_Approved-holder .university-logo-slider-holder {
    height: 200px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 10px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .img-holder
    .university-logo-img {
    height: 80%;
    width: 80%;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev:after,
  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-next:after {
    font-size: 15px;
  }

  .Top_Approved
    .Top_Approved-holder
    .university-logo-slider-holder
    .logo-slider
    .swiper-button-prev {
    left: 45%;
  }
}
